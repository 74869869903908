<template>
  <v-dialog v-model="dialog" persistent :overlay="false" max-width="1050px" transition="dialog-transition">
    <v-card>
      <v-card-title primary-title>
        Gestion des flux prévisionnels
      </v-card-title>
      <v-card-text class="pa-3">

        <h-group>
          <div style="width: 140px">
            <v-menu ref="menuDateDebut" v-model="menuDateDebut" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateDebut" style="width: 200px" label="Date de début" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateDebut" @input="menuDateDebut = false" @change="changementDateEventHandler" ></v-date-picker>
            </v-menu>
          </div>

          <div style="width: 140px">
            <v-menu ref="menuDateFin" v-model="menuDateFin" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateFin" style="width: 200px" label="Date de fin" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFin" @input="menuDateFin = false" @change="changementDateEventHandler" ></v-date-picker>
            </v-menu>
          </div>

          <v-btn color="success" small class="ml-3" @click="boutonMoisEncoursClickEventHandler">mois en cours</v-btn>
          <v-btn color="success" small class="ml-1" @click="boutonMoisMoinsUnClickEventHandler">mois - 1</v-btn>
          <v-btn color="success" small class="ml-1" @click="boutonMoisPlusUnClickEventHandler">mois + 1</v-btn>
          

        </h-group>

        <div >
          <v-data-table v-model="selected" :sort-by="['trajet', 'frDepartLieu']" multi-sort :loading="loading" dense fixed-header height="400" :headers="headers" item-key="frId" :items="frs" show-select class="elevation-1" :items-per-page="1000">
            <!-- colonne index -->
            <template #item.frDepartDateTheorique="{ item }">
              {{ formatDate(item.frDepartDateTheorique) }}
            </template>
            <template #item.frArriveeDateTheorique="{ item }">
              {{ formatDate(item.frArriveeDateTheorique) }}
            </template>
          </v-data-table>
        </div>

        <div class="my-2 vert" >{{ infos }}</div>
        <div class="my-2 rouge" v-if="erreur != ''">{{ erreur }}</div>

      </v-card-text>
      <v-card-actions>
        <div style="color: red">Attention, toute suppression est irreversible.</div>
        <v-spacer></v-spacer>
        <v-btn style="color: #FF9800" :disabled="selected.length == 0" @click="boutonSupprimerFluxClickHandler" :loading="loadingSupprimer">supprimer les flux sélectionnés</v-btn>
        <v-btn text @click="dialog = false" :loading="loadingSupprimer">annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  components: {},

  data() {
    return {
      fr: {
        type: Object,
        default: {}
      } ,
      loading: false,
      dialog: false,
      dateDebut: new Date().toISOString().substr(0, 10),
      dateFin: new Date().toISOString().substr(0, 10),
      menuDateDebut: false,
      menuDateFin: false,
      loadingSupprimer: false,
      infos: "",
      erreur: "",
      frs: [],
      headers:[
        { text: 'id', value: 'frId' },
        { text: 'Trajet', value: 'trajet' },
        { text: 'Départ', value: 'frDepartDateTheorique' },
        { text: 'Arrivée', value: 'frArriveeDateTheorique' }
      ],
      selected: []
    }
  },

  created() {
    const timeDebut = new Date().getTime() - 86400 * 1000 * 30
    this.dateDebut = new Date(timeDebut).toISOString().substr(0, 10)
    const timeFin = new Date().getTime() + 86400 * 1000 * 30
    this.dateFin = new Date(timeFin).toISOString().substr(0, 10)
  },

  methods: {
    open() {
      this.rafraichir()
    },

    rafraichir() {
      this.dialog = true
      this.loading = true;
      //var sdDateDebut = this.dateDebut.toISOString().substr(0, 10);
      var filter = {
        "where": {
          "and": [
            {
              "frDepartDateTheorique": {
                "gte": this.dateDebut
              }
            },
            {
              "frDepartDateTheorique": {
                "lt": this.dateFin
              }
            },            {
              "frReference": ""
            },
            {
              "or": [
              {
                  "frStatut": this.$FLUX_STATUT_PREVISIONNEL 
                },
                {
                  "frStatut": this.$FLUX_STATUT_COUVERT
                }
              ]
            },
            {
              "frAnnulé": 0
            }
          ]
        }
      }
      axios.get("/api/frFeuilleRoutes?filter=" + encodeURIComponent(JSON.stringify(filter)) + "&access_token=" + this.$user.id)
        .then(function(response) {
          response.data.forEach(r => r.trajet = r.frDepartLieu + " -> " + r.frArriveeLieu)
          this.frs = response.data
          this.loading = false;
        }.bind(this))
        .catch(error => {
            this.loading = false;
            if (error.response.status == 401) {
              this.afficherErreur('Accès refusé')
            }
            else {
              this.afficherErreur(error.message + " : " + error.response.statusText)
            }
          })
    },

    formatDate(date) {
      if (date == undefined) {
        return ""
      }
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const event = new Date(date)
      return event.toLocaleDateString('fr-Fr', options)
    },

    afficherErreur(message) {
      this.erreur = message
    },

    supprimerFlux(frId) {
      this.infos = "Suppression flux n°" + this.fluxASupprimer[this.indexFlux].frId
      const url = "/api/frFeuilleRoutes/supprimer?frId=" + frId + "&access_token=" + this.$user.id;
        axios.get(url)
          .then(function() {
            this.indexFlux++
            if (this.indexFlux < this.fluxASupprimer.length) {
              this.supprimerFlux(this.fluxASupprimer[this.indexFlux].frId)
            }
            else {
              this.infos = ""
              this.dialog = false
              this.loadingSupprimer = false;
              this.$emit("fluxmodifie")
            }
          }.bind(this))
          .catch(error => {
            this.loadingSupprimer = false;
            if (error.response.status == 401) {
              // Erreur "accès refusé", redirection vers la page de connexion
              this.afficherErreur('Accès refusé')
              //this.$router.push('/login?to=/containers')
            }
            else {
              this.afficherErreur(error.message + " : " + error.response.statusText)
            }
          })
    },

    boutonSupprimerFluxClickHandler() {
      this.erreur = ""
      const ok = confirm("Confirmer la suppression complète des flux sélectionnés ?")
      if (ok) {
        this.loadingSupprimer = true;
        this.indexFlux = 0
        this.fluxASupprimer = JSON.parse(JSON.stringify(this.selected))
        if (this.fluxASupprimer.length > 0) {
          this.supprimerFlux(this.fluxASupprimer[this.indexFlux].frId)
        }
        else {
          this.loadingSupprimer = true;
          this.erreur = "Aucun flux sélectionné."
        }
      }
    },    

    changementDateEventHandler() {
      this.rafraichir()
    },

    boutonMoisEncoursClickEventHandler() {
      const timeDebut = new Date()
      this.dateDebut = new Date(timeDebut.getFullYear(), timeDebut.getMonth(), 1, 12, 0, 0).toISOString().substr(0, 10)
      var anneeSuivante = timeDebut.getFullYear()
      var moisSuivant = timeDebut.getMonth() + 1
      if (moisSuivant == 12) {
        moisSuivant = 0
        anneeSuivante++
      }
      this.dateFin = new Date(anneeSuivante, moisSuivant, 1, 12, 0, 0).toISOString().substr(0, 10)
      this.rafraichir();
    },

    boutonMoisPlusUnClickEventHandler() {
      const timeDebut = new Date(this.dateDebut)
      var anneeSuivante = timeDebut.getFullYear()
      var moisSuivant = timeDebut.getMonth() + 1
      if (moisSuivant == 12) {
        moisSuivant = 0
        anneeSuivante++
      }
      this.dateDebut = new Date(anneeSuivante, moisSuivant, 1, 12, 0, 0).toISOString().substr(0, 10)
      const timeFin = new Date(this.dateFin)
      anneeSuivante = timeFin.getFullYear()
      moisSuivant = timeFin.getMonth() + 1
      if (moisSuivant == 12) {
        moisSuivant = 0
        anneeSuivante++
      }
      this.dateFin = new Date(anneeSuivante, moisSuivant, 1, 12, 0, 0).toISOString().substr(0, 10)
      this.rafraichir();
    },

    boutonMoisMoinsUnClickEventHandler() {
      const timeDebut = new Date(this.dateDebut)
      var anneeSuivante = timeDebut.getFullYear()
      var moisSuivant = timeDebut.getMonth() - 1
      if (moisSuivant == -1) {
        moisSuivant = 11
        anneeSuivante--
      }
      this.dateDebut = new Date(anneeSuivante, moisSuivant, 1, 12, 0, 0).toISOString().substr(0, 10)
      const timeFin = new Date(this.dateFin)
      anneeSuivante = timeFin.getFullYear()
      moisSuivant = timeFin.getMonth() - 1
      if (moisSuivant == -1) {
        moisSuivant = 11
        anneeSuivante--
      }
      this.dateFin = new Date(anneeSuivante, moisSuivant, 1, 12, 0, 0).toISOString().substr(0, 10)
      this.rafraichir();
    }


  }
}
</script>

<style>
.groupe {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 300px;
  height: 200px;
  background-color: #F0F0F0;
  margin: 10px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titre {
  color: #2196F3;
  font-weight: bold;
}

.soustitre {
  color: #2196F3;
}

.vert {
  color: #4CAF50;
}

.rouge {
  color: #F44336;
}

.corange {
  color: #FF9800;
}

.boutonFermer {
  color: #2196F3;
}

.boutonAnnuler {

}

.boutonSupprimer {

}

</style>