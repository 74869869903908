var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"1050px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Gestion des flux prévisionnels ")]),_c('v-card-text',{staticClass:"pa-3"},[_c('h-group',[_c('div',{staticStyle:{"width":"140px"}},[_c('v-menu',{ref:"menuDateDebut",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"200px"},attrs:{"label":"Date de début","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateDebut),callback:function ($$v) {_vm.dateDebut=$$v},expression:"dateDebut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateDebut),callback:function ($$v) {_vm.menuDateDebut=$$v},expression:"menuDateDebut"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDateDebut = false},"change":_vm.changementDateEventHandler},model:{value:(_vm.dateDebut),callback:function ($$v) {_vm.dateDebut=$$v},expression:"dateDebut"}})],1)],1),_c('div',{staticStyle:{"width":"140px"}},[_c('v-menu',{ref:"menuDateFin",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"200px"},attrs:{"label":"Date de fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFin),callback:function ($$v) {_vm.dateFin=$$v},expression:"dateFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFin),callback:function ($$v) {_vm.menuDateFin=$$v},expression:"menuDateFin"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDateFin = false},"change":_vm.changementDateEventHandler},model:{value:(_vm.dateFin),callback:function ($$v) {_vm.dateFin=$$v},expression:"dateFin"}})],1)],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success","small":""},on:{"click":_vm.boutonMoisEncoursClickEventHandler}},[_vm._v("mois en cours")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"success","small":""},on:{"click":_vm.boutonMoisMoinsUnClickEventHandler}},[_vm._v("mois - 1")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"success","small":""},on:{"click":_vm.boutonMoisPlusUnClickEventHandler}},[_vm._v("mois + 1")])],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":['trajet', 'frDepartLieu'],"multi-sort":"","loading":_vm.loading,"dense":"","fixed-header":"","height":"400","headers":_vm.headers,"item-key":"frId","items":_vm.frs,"show-select":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.frDepartDateTheorique",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.frDepartDateTheorique))+" ")]}},{key:"item.frArriveeDateTheorique",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.frArriveeDateTheorique))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"my-2 vert"},[_vm._v(_vm._s(_vm.infos))]),(_vm.erreur != '')?_c('div',{staticClass:"my-2 rouge"},[_vm._v(_vm._s(_vm.erreur))]):_vm._e()],1),_c('v-card-actions',[_c('div',{staticStyle:{"color":"red"}},[_vm._v("Attention, toute suppression est irreversible.")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"#FF9800"},attrs:{"disabled":_vm.selected.length == 0,"loading":_vm.loadingSupprimer},on:{"click":_vm.boutonSupprimerFluxClickHandler}},[_vm._v("supprimer les flux sélectionnés")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loadingSupprimer},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("annuler")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }