<template>
  <v-dialog v-model="dialog"  persistent :overlay="false" max-width="800px" max-height="600px" transition="dialog-transition">
    <v-snackbar v-model="snackErreur" color="red">
      {{snackErreurMessage}}
      <v-btn text color="white" @click.native="snackErreur = false">fermer</v-btn>
    </v-snackbar>

    <v-card>

      <v-card-title primary-title >
        Importation de feuilles de route prévisionnelles
      </v-card-title>

      <v-card-text>
        <div style="max-height: 400px; overflow: auto;">
          <v-data-table :headers="headersFeuilles" :items="feuilles" hide-default-footer :items-per-page="1000" class="elevation-1" pagination.sync="pagination">
          </v-data-table>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="collerClick()">coller</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Annuler</v-btn>
        <v-btn color="success" :loading="loading" :disabled="feuilles.length == 0" @click="boutonImporterClick">importer</v-btn>
      </v-card-actions>
    </v-card>
    
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      dialog: false,
      loading: false,
      snackErreur: false,
      snackErreurMessage: "",
      valid: false,

      feuilles: [],

      headersFeuilles: [
      { text: 'Lieu de départ', value: 'frDepartLieu' },
         { text: 'Date de départ', value: 'frDepartDateTheorique'},
         { text: 'Lieu d\'arrivée', value: 'frArriveeLieu' },
         { text: 'Date d\'arrivée', value: 'frArriveeDateTheorique'}
      ],
    }
  },

  props: {
  },


  methods: {
    afficherErreur(message) {
      this.loading = false;
      this.snackErreurMessage = message;
      this.snackErreur = true;
    },

    open() {
      this.feuilles = []
      this.dialog = true
    },

    collerClick() {
      navigator.clipboard
        .readText()
        .then((clipText) => {
          let lines = clipText.split('\r\n')
          let frs = []
          for(var idx = 1; idx < lines.length; idx++) {
            let line = lines[idx]
            if (line.trim() != "") {
              console.log("line", line)
              let fields = line.split("\t")
              var fr = {
                frDepartLieu: fields[0],
                frDepartDateTheorique: fields[1],
                frArriveeLieu: fields[2],
                frArriveeDateTheorique: fields[3]
              }
              frs.push(fr)
            }
          }
          this.feuilles = frs;
        });
    },

    boutonImporterClick() {
      this.$dayjs.locale("fr")
      if (this.feuilles.length == 0) {
        return
      }
      // Contrôle des valeurs
      const frs = []
      this.feuilles.forEach(fr => {
        var d = this.importParseDate(fr.frDepartDateTheorique);
        if (d == undefined) {
          alert("La date de départ'" + fr.frDepartDateTheorique + "' est invalide.'")
          return
        }
        d = this.importParseDate(fr.frArriveeDateTheorique);
        if (d == undefined) {
          alert("La date d'arrivée '" + fr.frArriveeDateTheorique + "' est invalide.'")
          return
        }

        if (fr.frDepartLieu == "") {
          alert("Le lieu de départ '" + fr.frDepartLieu + "' est invalide.'")
          return
        }
        if (fr.frDepartLieu.toLowerCase() == "VGS") {
          fr.frDepartLieu = "VOSGES"
        }

        if (fr.frArriveeLieu == "") {
          alert("Le lieu d'arrivée '" + fr.frArriveeLieu + "' est invalide.'")
          return
        }
        if (fr.frArriveeLieu.toLowerCase() == "VGS") {
          fr.frArriveeLieu = "VOSGES"
        }

        frs.push({
          frDateCreation: new Date(),
          frSource: "Import manuel " + new Date().toISOString() + " " + this.$user.user.username,
          frDepartLieu: fr.frDepartLieu,
          frDepartDateTheorique: this.importParseDate(fr.frDepartDateTheorique),
          frArriveeLieu: fr.frArriveeLieu,
          frArriveeDateTheorique: this.importParseDate(fr.frArriveeDateTheorique),
          frReference: '',
          frStatut: this.$FLUX_STATUT_PREVISIONNEL
        })

      });

      // Envoi de la requête
      this.loading = true;
      console.log("frs", JSON.stringify(frs))
      axios.post("/api/frFeuilleRoutes?access_token=" + this.$user.id, frs)
      .then(function () {
        this.$emit("importationTerminee")
        this.loading = false;
        this.dialog = false
      }.bind(this))
      .catch(error => {
        this.loading = false;
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.purchaseOrders = [];
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
          this.purchaseOrders = [];
        }
      });      
    },

    importParseDate(date) {
      date = date.toLowerCase()
      const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
      const f = date.split(" ")
      const idxD = f.length == 4 ? 1 : 0
      const idxM = f.length == 4 ? 2 : 1
      const idxY = f.length == 4 ? 3 : 2
      const dd = parseInt(f[idxD])
      const mm = months.indexOf(f[idxM])
      const yyyy = parseInt(f[idxY])
      try {
        const d = new Date(yyyy, mm, dd)
        return d
      }
      catch(error) {
        console.log("error", error)
        return
      }

    }
  }

}
</script>

<style>

</style>