<template>
  <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1200px" transition="dialog-transition">
    <v-card>
      <v-card-title primary-title>
        Note
      </v-card-title>
      
      <v-card-text>
        <div id="saisie" ref="saisie"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="success" @click="boutonOkClickHandler">Ok</v-btn>
        <v-btn text @click="dialog = false">annuler</v-btn>
      </v-card-actions>

    </v-card>    
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
import { createEditor } from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'

Vue.use(Vuex);

export default {
  data() {
    return {
      dialog: false,
      initialise: false,
    }
  },

  methods: {

    ouvrir(nt, frId) {
      this.dialog = true
      this.nt = nt ? nt : {
        ntFrId: frId,
        ntNote: '',
        ntAuteur: this.$user.user.username,
      }
      if (!this.initialise) {
        this.initialise = true;
        setTimeout(function() {
          var editeur = createEditor('#saisie', {uploadUrl: '', id: '', classList: ["editeurnote"], fontName: [{val: 'Arial'}]
            });
          editeur.$store.dispatch('execCommand', {name: 'fontName', value: 'Arial, sans-serif'})
          editeur.$store.dispatch('execCommand', {name: 'fontSize', value: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem']})
          this.$refs.saisie.editeur = editeur;
          this.$refs.saisie.editeur.setContent(this.nt.ntNote);
        }.bind(this), 500);
      }
      else {
        this.$refs.saisie.editeur.setContent(this.nt.ntNote);
      }
    },

    boutonOkClickHandler() {
      if (!this.nt.ntId)  {
        // Création d'un nouvelle note
        this.nt.ntNote = this.$refs.saisie.editeur.getContent()
        this.nt.ntDateCreation = new Date()
        axios.post("/api/ntNotes", this.nt)
          .then(function(response) {
            this.$emit("note-creee", response.data);
            this.dialog = false
          }.bind(this))
      }
      else {
        // Modification d'une note existante
        this.nt.ntNote = this.$refs.saisie.editeur.getContent()
        this.nt.ntDateModification = new Date(); //this.moment(new Date().toISOString(), "YYYY-MM-DD HH:mm:ss.SSSZ").add(this.moment().utcOffset()).format("YYYY-MM-DD HH:mm:ss")
        axios.put("/api/ntNotes", this.nt)
          .then(function(response) {
            this.$emit("note-modifiee", response.data);
            this.dialog = false
          }.bind(this))
      }
    }
  }
}
</script>

<style>

.editeurnote {
  height: 500px;
}
</style>