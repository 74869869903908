<template>
  <v-dialog v-model="dialog" persistent :overlay="false" max-width="1050px" transition="dialog-transition">
    <v-card>
      <v-card-title primary-title>
        Modifier le flux
      </v-card-title>
      <v-card-text class="pa-3">
          <div>
            <table class="mb-5">
              <tr><td>id</td><td><h3 style="text-align: left;margin-left: 20px;">{{fr.frId}}</h3></td></tr>
              <tr><td>Lieu de départ / date</td><td><h3 style="text-align: left;margin-left: 20px;">{{fr.frDepartLieu}} / {{formatDate(fr.frDepartDateTheorique)}}</h3></td></tr>
              <tr><td>Lieu d'arrivée / date</td><td><h3 style="text-align: left;margin-left: 20px;">{{fr.frArriveeLieu}} / {{formatDate(fr.frArriveeDateTheorique)}}</h3></td></tr>
            </table>
          </div>

          <h-group style="align-items: start; text-align: center;">
            <!-- Partie fermer le flux -->
            <div class="groupe">
              <div class="titre vert">Fermeture manuelle du flux</div>
              <div class="soustitre vert">Ce flux sera considéré comme bien arrivé. Saisir la date d'arrivée réelle.</div>
              <h-group>
                <v-text-field label="Date" type="date" v-model="fermerFeuilleRouteDateArrivee" class="mr-4"></v-text-field>
                <v-text-field label="Heure" type="time" v-model="fermerFeuilleRouteHeureArrivee"></v-text-field>
              </h-group>
              <v-spacer></v-spacer>
              <v-btn v-if="!loadingFermer0" style="color: #4CAF50" :loading="loadingFermer" @click="loadingFermer0 = true">fermer le flux</v-btn>
              <div v-if="loadingFermer0">Confirmer la fermeture de ce flux ?</div>
              <h-group v-if="loadingFermer0">
                <v-btn @click="loadingFermer0 = false" color="#F0F0F0" >non</v-btn>
                <v-btn class="ml-2" color="#4CAF50" @click="boutonFermerFluxClickHandler" >oui</v-btn>
              </h-group>
            </div>

            <!-- Partie annulation du flux -->
            <div class="groupe">
              <div class="titre rouge">Annulation du flux</div>
              <div class="soustitre rouge">
                Ce flux sera considéré comme annulé.
                Il restera présent dans le suivi avec un fond rouge.
                Et il sera pris en compte dans les KPI.
              </div>
              <v-autocomplete clearable label="Motif" :items="motifsAnnulation" item-value="maDescription" item-text="maDescription" v-model="motifAnnulation"></v-autocomplete>
              <v-spacer></v-spacer>
              <v-btn v-if="!loadingAnnuler0" style="color: #F44336" @click="loadingAnnuler0 = true" :loading="loadingAnnuler">annuler le flux</v-btn>
              <div v-if="loadingAnnuler0">Confirmer l'annulation de ce flux ?</div>
              <h-group v-if="loadingAnnuler0">
                <v-btn @click="loadingAnnuler0 = false" color="#F0F0F0" >non</v-btn>
                <v-btn class="ml-2" color="#F44336" @click="boutonAnnulerFluxClickHandler" >oui</v-btn>
              </h-group>
            </div>

            <!-- Partie suppression complète du flux -->
            <div class="groupe">
              <div class="titre corange">Suppression complète du flux</div>
              <div class="soustitre corange">
                Ce flux n'apparaîtra plus dans le suivi.
                Et il ne sera pas pris en compte dans les KPI.
                Les trains, PO et wagons associés seront également supprimés.
              </div>
              <v-spacer></v-spacer>
              <v-btn v-if="!loadingSupprimer0" style="color: #FF9800" @click="loadingSupprimer0 = true">supprimer le flux</v-btn>
              <div v-if="loadingSupprimer0">Confirmer la suppression de ce flux ?</div>
              <h-group v-if="loadingSupprimer0">
                <v-btn @click="loadingSupprimer0 = false" color="#F0F0F0" >non</v-btn>
                <v-btn class="ml-2" color="#FF9800" @click="boutonSupprimerFluxClickHandler" >oui</v-btn>
              </h-group>
            </div>

          </h-group>

          <div class="my-2 rouge" v-if="erreur != ''">{{ erreur }}</div>

      </v-card-text>
      <v-card-actions>
        <div style="color: red">Attention, toute modification sur le flux est irreversible.</div>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HGroup from '@/components/flex/HGroup.vue';
import axios from 'axios';

export default {
  components: {HGroup},

  data() {
    return {
      fr: {
        type: Object,
        default: {}
      } ,
      dialog: false,
      fermerFeuilleRouteDateArrivee: new Date().toISOString().substr(0, 10),
      fermerFeuilleRouteHeureArrivee: "08:00",
      menuDateArrivee: false,
      loadingFermer0: false,
      loadingFermer: false,
      loadingAnnuler: false,
      loadingAnnuler0: false,
      loadingSupprimer: false,
      loadingSupprimer0: false,
      erreur: "",
      motifsAnnulation: [],
      motifAnnulation: ""
    }
  },

  methods: {
    open(fr) {
      this.loadingFermer0 = false
      this.loadingAnnuler0 = false
      this.loadingSupprimer0 = false
      this.fr = fr;
      this.fermerFeuilleRouteDateArrivee = this.fr.frArriveeDateTheorique.substr(0, 10)
      this.fermerFeuilleRouteHeureArrivee = "08:00"
      console.log("fr", fr)
      this.dialog = true

    },

    formatDate(date) {
      if (date == undefined) {
        return ""
      }
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const event = new Date(date)
      return event.toLocaleDateString('fr-Fr', options)
    },

    afficherErreur(message) {
      this.erreur = message
    },

    boutonFermerFluxClickHandler() {
      this.erreur = ""
      this.loadingFermer0 = false;
      this.loadingFermer = true;
      const url = "/api/frFeuilleRoutes?access_token=" + this.$user.id;
      if ((this.fr.frReference == undefined) || (this.fr.frReference == null) | (this.fr.frReference == "")) {
        this.fr.frReference = "-"
      }
      this.fr.frStatut = this.$FLUX_STATUT_FERME
      this.fr.frArriveeDateReelle = this.fermerFeuilleRouteDateArrivee + " " + this.fermerFeuilleRouteHeureArrivee
      axios.put(url, this.fr)
        .then(function() {
          this.dialog = false
          this.loadingFermer = false;
          this.$emit("fluxmodifie")
        }.bind(this))
        .catch(error => {
          this.loadingFermer = false;
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.afficherErreur('Accès refusé')
            //this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
          }
        })
    },

    boutonAnnulerFluxClickHandler() {
      this.erreur = ""
      this.loadingAnnuler0 = false
      this.loadingAnnuler = true;
      const url = "/api/frFeuilleRoutes?access_token=" + this.$user.id;
      if ((this.fr.frReference == undefined) || (this.fr.frReference == null) | (this.fr.frReference == "")) {
        this.fr.frReference = "-"
      }
      this.fr.frStatut= this.$FLUX_STATUT_ANNULE
      this.fr.frMotifAnnulation = this.motifAnnulation
      axios.put(url, this.fr)
        .then(function() {
          this.dialog = false
          this.loadingAnnuler = false;
          this.$emit("fluxmodifie")
        }.bind(this))
        .catch(error => {
          this.loadingAnnuler = false;
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.afficherErreur('Accès refusé')
            //this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
          }
        })
    },    

    boutonSupprimerFluxClickHandler() {
      this.erreur = ""
      this.loadingSupprimer0 = false;
      this.loadingSupprimer = true;
      const url = "/api/frFeuilleRoutes/supprimer?frId=" + this.fr.frId + "&access_token=" + this.$user.id;
      axios.get(url)
        .then(function() {
          this.dialog = false
          this.loadingSupprimer = false;
          this.$emit("fluxmodifie")
        }.bind(this))
        .catch(error => {
          this.loadingSupprimer = false;
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.afficherErreur('Accès refusé')
            //this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
          }
        })
    },    
  }
}
</script>

<style scoped>
.groupe {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 300px;
  height: 240px;
  background-color: #F0F0F0;
  margin: 10px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titre {
  color: #2196F3;
  font-weight: bold;
}

.soustitre {
  color: #2196F3;
}

.vert {
  color: #4CAF50;
}

.rouge {
  color: #F44336;
}

.corange {
  color: #FF9800;
}

.boutonFermer {
  color: #2196F3;
}

.boutonAnnuler {

}

.boutonSupprimer {

}

</style>